/**
 * 分类icon
 */
const menuIcons = {
  'basic': 'icon-jibenxinxi',
  'language': 'icon-yuyan',
  'positionInformation': 'icon-zhichengxinxi',
  'bank': 'icon-yinhangkaxinxi',
  'work': 'icon-gongzuojingli',
  'train': 'icon-peixunjingli',
  'skill': 'icon-jinengzhuanchang',
  'enclosure': 'icon-fujian',
  'teach': 'icon-jiaoyujingli',
  'sociology': 'icon-shehuijingli',
  'custom': 'icon-morenerji'
}

export default {
  menuIcons
}