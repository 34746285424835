<template>
  <!-- :class="{ wrappermodel: !footerShow }" -->
  <div class="wrapper">
    <div class="user-con">
      <div
        :id="[`scroll-${item.id}`]"
        class="user-dom"
        v-for="(item, index) in list"
        :key="index"
      >
        <div
          class="user-item"
          @click="spanClick(item)"
          :class="{ 'user-border': item.editShow }"
        >
          <div class="uesr-tit">
            <svg class="closeImg" aria-hidden="true">
              <use :xlink:href="'#' + item.icon" />
            </svg>
            {{ item.text }}
            <span class="require-txt" v-if="item.isNotNull === 1">必填</span>
          </div>
          <div
            class="uesr-icon"
            :class="{ 'color-blue': item.editShow && footerShow }"
          >
            <span
              class="iconfont"
              :class="
                item.editShow && footerShow ? 'icon-biji' : 'icon-xiangzuo'
              "
            ></span>
          </div>
        </div>
        <div class="user-m" v-if="item.editShow && item.type !== 'basic'">
          <div class="user-c" v-if="item.type === 'bank'">
            <!-- 工资卡信息 -->
            <div class="user-c-tit">
              {{ item.map.payee_name }}
            </div>
            <div class="user-c-m">
              <div class="user-c-l">
                {{ item.map.bank_account }}
              </div>
            </div>
            <div class="color-blue">
              {{ item.map.bank_name }}
            </div>
          </div>
          <div class="user-c" v-if="item.type === 'work'">
            <!-- 工作经历 -->
            <div class="user-c-tit">
              {{ item.map.previous_company }}
            </div>
            <div class="user-c-m" v-if="item.map.working_end_date">
              <div class="user-c-l">
                {{ item.map.title_of_position }}
              </div>
              <div class="user-c-r color-back">
                {{ item.map.working_end_date }}
              </div>
            </div>
            <div class="font-size11 color-blue">
              {{ item.map.internship || item.map.latest_working_experience }}
            </div>
          </div>
          <div class="user-c" v-if="item.type === 'enclosure'">
            <!-- 其他证件 -->
            <div class="user-c-m">
              <div class="user-c-l">
                {{ item.map.local_residence_code }}
              </div>
              <div class="user-c-r color-blue">
                {{ item.map.local_residence_effective_date }}
              </div>
            </div>
            <div class="font-size13 color-back">{{ item.map.grant_time }}</div>
          </div>
          <div class="user-c" v-if="item.type === 'positionInformation'">
            <!-- 职称信息 -->
            <div class="user-c-m">
              <div class="user-c-l">
                {{ item.map.professional_title }}
              </div>
              <div class="user-c-r color-blue">
                {{ item.map.professional_grade }}
              </div>
            </div>
            <div class="font-size13 color-back">{{ item.map.grant_time }}</div>
          </div>
          <div class="user-c" v-if="item.type === 'teach'">
            <!-- 教育经历 -->
            <div class="user-c-tit">
              {{ item.map.fstablishment }}
            </div>
            <div class="user-c-m">
              <div class="user-c-l">
                {{ item.map.major }}
              </div>
              <div class="user-c-r color-back">
                <span v-if="item.map.educational_start_date"
                  >{{ item.map.educational_start_date }}
                </span>
                <!-- <span v-if="item.map.educational_end_date">{{
                  item.map.educational_end_date
                }}</span> -->
              </div>
            </div>
          </div>
          <div class="user-c" v-if="item.type === 'sociology'">
            <!-- 社会关系 -->
            <div class="user-c-m">
              <div class="user-c-l">
                {{ item.map.contacts_name }}
              </div>
              <div class="user-c-r color-blue">
                {{ item.map.contacts_relationship }}
              </div>
            </div>
            <div class="user-c-tit padd5">
              {{ item.map.contacts_number }}
            </div>
            <div class="font-size11 color-blue">
              {{ item.map.is_emergency_contact }}
            </div>
          </div>
          <div class="user-c" v-if="item.type === 'skill'">
            <!-- 技能专长 -->
            <div class="user-c-m">
              <div class="user-c-l">
                {{ item.map.skills_type }}
              </div>
              <div class="user-c-r color-blue">
                {{ item.map.skills_proficiency }}
              </div>
            </div>
          </div>
          <div class="user-c" v-if="item.type === 'train'">
            <!-- 培训经历 -->
            <div class="user-c-tit">
              {{ item.map.traning_event }}
            </div>
            <div class="user-c-m">
              <div class="user-c-l">
                {{ item.map.training_establishment }}
              </div>
              <div class="user-c-r color-back">
                {{ item.map.start_date }}
              </div>
            </div>
          </div>
          <div class="user-c" v-if="item.type === 'language'">
            <!-- 语言 -->
            <div class="user-c-m">
              <div class="user-c-l">
                {{ item.map.language_type }}
              </div>
              <div class="user-c-r color-blue">
                {{ item.map.language_fluency }}
              </div>
            </div>
          </div>
          <div class="user-c" v-if="item.type === 'enclosure'">
            <!-- 附件 -->
            <div class="user-c-m">
              <div
                class="user-img"
                v-for="(dom, index) in item.data[0].value"
                :key="index"
              >
                <!-- <div class="user-img-tit">
                  {{ dom.label }}
                </div> -->
                <div class="user-c-img">
                  <UploadImgView
                    :label="dom.label"
                    :value.sync="dom.value"
                    :disabled="true"
                  >
                  </UploadImgView>
                </div>
              </div>
            </div>
          </div>
          <div class="user-c" v-if="item.type === 'custom'">
            <!-- 自定义 -->
            <div class="user-c-m">
              <div class="user-c-l">
                {{ item.map.value }}
              </div>
              <div class="user-c-r color-blue">
                {{ item.map.title }}
              </div>
            </div>
          </div>
        </div>
        <div class="user-m-basic" v-if="item.editShow && item.type === 'basic'">
          <div class="user-c">
            <!-- 基本信息 -->
            <van-cell-group>
              <van-cell v-for="(dom, index) in item.data" :key="index">
                <OcrIdentityView
                  v-if="dom.type === 'uploadImg01'"
                  :value.sync="dom.value"
                  :disabled="true"
                ></OcrIdentityView>
                <van-field
                  v-if="dom.type === 'text'"
                  v-model="dom.value"
                  :label="dom.label"
                  readonly
                  :required="dom.required"
                  placeholder="请填写"
                  right-icon="custom"
                  input-align="right"
                >
                  <van-icon
                    class="iconfont icon-kong"
                    class-prefix="icon"
                    slot="right-icon"
                    name="custom"
                  ></van-icon>
                </van-field>
                <van-field
                  v-if="dom.type === 'number'"
                  v-model="dom.value"
                  :label="dom.label"
                  readonly
                  placeholder="请填写"
                  :required="dom.required"
                  right-icon="custom"
                  input-align="right"
                >
                  <van-icon
                    class="iconfont icon-kong"
                    class-prefix="icon"
                    slot="right-icon"
                    name="custom"
                  ></van-icon>
                </van-field>
                <van-field
                  v-if="dom.type === 'input2'"
                  v-model="dom.value"
                  :label="dom.label"
                  :required="dom.required"
                  readonly
                  right-icon="custom"
                  placeholder="请填写"
                  input-align="right"
                >
                  <van-icon
                    class="iconfont"
                    :class="
                      cardCode
                        ? 'icon-zizhuruzhi-miwen'
                        : 'icon-zizhuruzhi-mingwen'
                    "
                    class-prefix="icon"
                    slot="right-icon"
                    name="custom"
                    @click="iconClick(dom)"
                  ></van-icon>
                </van-field>
              </van-cell>
            </van-cell-group>
          </div>
        </div>
      </div>
    </div>
    <div class="user-footer" v-if="footerShow">
      <van-button type="info" v-btnClick @click="save">提 交</van-button>
    </div>
    <!-- <div class="user-model" v-if="!footerShow" @touchmove.prevent>
      <div class="user-tit">您的入职信息在审核，请稍后查看</div>
    </div> -->
  </div>
</template>
<script>
import OcrIdentityView from "../../components/ocr/ocr-identity-view"; //身份证OCR
import API from "../../api/http";
import { mapGetters } from "vuex";
import info from "@/utils/info";
import untis from "../../utils";
import AreaList from "@/utils/area";
import UploadImgView from "../../components/upload-img-view/index"; //上传证书
export default {
  components: {
    OcrIdentityView,
    UploadImgView,
  },
  data() {
    return {
      list: [],
      cardCode: false,
      approvalStatus: "", //审批状态：10待审核，15审批中, 20已拒绝，30待完善，40审核通过
    };
  },
  computed: {
    footerShow() {
      let list = this.list.filter((item) => item.editShow);
      //人员状态未10、20,可以再次提交
      if (this.mcode) {
        if (this.approvalStatus === 10 || this.approvalStatus === 20) {
          return true;
        } else {
          false;
        }
      } else {
        if (list.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    ...mapGetters("user", {
      clientCode: "clientCode",
      ctFieldStatisticalId: "ctFieldStatisticalId",
      temporaryCode: "temporaryCode",
      mcode: "mcode",
    }),
  },
  created() {
    this.getInit();
  },
  mounted() {},
  methods: {
    setDomScroll() {
      //滚动到指定位置
      let backData = untis.getSessionStorage("backData");
      let isNotNull = this.list.filter((item) => item.text === backData);
      if (isNotNull.length === 0) return;
      let y =
        document.getElementById(`scroll-${isNotNull[0].id}`).offsetTop || 0;
      // window.scrollTo(0,y)
      window.scrollTo({
        top: y,
        behavior: "smooth",
      });
    },
    getInit() {
      API.getEntryTemByHtml({
        clientCode: this.clientCode,
        ctFieldStatisticalId: this.ctFieldStatisticalId,
        temporaryCode: this.temporaryCode,
        mcode: this.mcode,
      }).then((res) => {
        let result = res.data;
        this.list = this.setList(result);
        this.approvalStatus = this.list[0].approvalStatus;
        this.$nextTick(() => {
          this.setDomScroll();
        });
      });
    },
    setEduit(item, list) {
      //分类是否有过保存
      let flag = false;

      // 页面初始化时，默认展开身份信息
      if (item.path === "basic") {
        return (flag = true);
      }

      if (list.length > 0) {
        let arr = list.filter(
          (item) => item.isNotNull === 1 && item.fieldValue !== ""
        );
        if (arr.length > 0) {
          // //详情有必填字段
          flag = true;
        } else {
          //详情没有必填字段
          for (let key of list) {
            if (key.fieldValue && key.fieldValue !== "[]") {
              flag = true;
              break;
            }
          }
        }
        return flag;
      } else {
        return flag;
      }
    },
    setListData(type, list) {
      //分类历史信息的展示
      let map = {};
      if (list.length > 0) {
        if (type !== "custom") {
          //是否是自定义分类
          for (let val of list) {
            if (val.propertyType === "boolean") {
              map[val.fieldCode] = JSON.parse(val.fieldValue || "false")
                ? val.fieldDesc
                : "";
            } else {
              map[val.fieldCode] = val.fieldValue;
            }
          }
        } else {
          map.value = list[0].fieldValue;
          if (
            list[0].fieldCode === "contacts_mnccode" &&
            list[0].fieldValue !== ""
          ) {
            //国际区域码带 + 号
            map.value = "+" + list[0].fieldValue;
          }

          if (list.length >= 2) {
            let val = list[1].fieldValue;
            //地址信息展示判断

            if (
              list[1].fieldCode === "contacts_address_province_municipality"
            ) {
              let mm = JSON.parse(list[1].fieldValue || "[]");
              val = "";
              if (mm.length) {
                val = `${AreaList.province_list[mm[0]]}${
                  AreaList.city_list[mm[1]]
                }${AreaList.county_list[mm[2]]}`;
              }
            }
            map.title = val;
          }
        }
      }

      return map;
    },
    setList(arr) {
      let list = arr.map((item) => {
        let text = item.templateMenuDesc;
        let id = item.templateMenuId;
        let type = item.path;
        let icon = info.menuIcons[type];
        let path = "/" + type; //跳转的页面
        // item.path === 'basic'
        let editShow = this.setEduit(item, item.menuRelList); //this.setEduit(item.menuRelList);  改成只有身份信息显示详情，其它不显示
        let approvalStatus = item.approvalStatus;
        let map = this.setListData(type, item.menuRelList);

        let menuRelList1 = item.menuRelList.filter(
          (item) => item.propertyType === "uploadImg"
        );
        let menuRelList2 = item.menuRelList.filter(
          (item) => item.propertyType !== "uploadImg"
        );
        let arr1 = menuRelList1.map((child) => {
          return {
            label: child.fieldDesc,
            value: child.fieldValue,
            type: child.propertyType,
            fieldCode: child.fieldCode,
            required: child.isNotNull === 1 ? true : false,
          };
        });

        let data = menuRelList2.map((child) => {
          let type = child.propertyType;
          if (
            child.propertyType === "select" ||
            child.propertyType === "date"
          ) {
            type = "text";
          }
          return {
            label: child.fieldDesc,
            value: child.fieldValue,
            type: type,
            fieldCode: child.fieldCode,
            required: child.isNotNull === 1 ? true : false,
          };
        });
        if (arr1.length > 0) {
          const imgs01 = arr1.filter(
            (item) =>
              item.fieldCode === "id_front_side_url" ||
              item.fieldCode === "id_back_side_url"
          );
          const imgs02 = arr1.filter(
            (item) =>
              item.fieldCode !== "id_front_side_url" &&
              item.fieldCode !== "id_back_side_url"
          );
          data.unshift({
            label: "",
            type: "uploadImg01",
            value: [...imgs01],
          });
          data.push(...imgs02);
        }

        return {
          map,
          editShow, //分类是否保存过内容
          text,
          id,
          type,
          isNotNull: item.isNotNull, //分类是否必填 1：必填 0：不必填
          menuLayout: item.menuLayout,
          icon,
          path,
          data,
          approvalStatus,
        };
      });

      return list;
    },
    spanClick(item) {
      untis.removeSessionStorage("backData");
      //页面跳转
      this.$router.push({
        path: item.path,
        query: {
          menuLayout: item.menuLayout, //可以添加类型的个数
          astatus: item.approvalStatus, //审核状态
          ...this.$route.query, //模版ID和clientCode
          id: item.id,
          title: item.text,
        },
      });
    },
    save() {
      //大保存
      API.saveFieldVale({
        clientCode: this.clientCode,
        ctFieldStatisticalId: this.ctFieldStatisticalId,
        temporaryCode: this.temporaryCode,
        mcode: this.mcode,
      }).then((res) => {
        untis.removeSessionStorage("backData");
        let arr = res.data || [];
        //拼接数据给错误页面用
        arr = arr.map((element) => {
          let obj = {};
          this.list.forEach((cItem) => {
            if (element.templateMenuId === cItem.id) {
              obj = {
                ...cItem,
                ...element,
              };
            }
          });
          return obj;
        });

        let flag = arr.some((item) => item.state === 0);
        if (flag) {
          this.$router.push({
            path: "/submitErr",
            query: { ...this.$route.query },
          });
          this.$store.commit("user/SET_ERRLIST", arr);
        } else {
          this.$router.replace({
            path: "/404",
            query: { type: 200 },
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.wrappermodel {
  width: 100%;
  pointer-events: none;
  position: fixed;
}
.user-model {
  position: fixed;
  pointer-events: none;
  top: 50px;
  left: 0;
  z-index: 111;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  .user-tit {
    margin-top: 200px;
    display: inline-block;
    color: #fff;
    padding: 8px 44px;
    border-radius: 22px;
    background-color: rgba(0, 0, 0, 0.9);
  }
}
.color-blue {
  color: #2e98ff;
}
.color-back {
  color: #bfbfbf;
}
.padd5 {
  padding: 5px 0;
}
.wrapper {
  padding-left: 15px;
  padding-right: 15px;
  min-height: 100%;
}
.user-con {
  padding-bottom: 60px;
  margin-top: 10px;
}

.user-dom {
  border-radius: 6px;
  margin-bottom: 10px;
  padding: 0 12px;
  box-sizing: border-box;
  background-color: #fff;
}
.user-c > .van-cell-group > .van-cell {
  padding: 0px 0;
}
.user-m {
  color: #595959;
  padding: 12px 12px 12px 0;
  .user-c {
    text-align: left;
  }
  .user-c-m {
    display: flex;
    padding: 5px 0;
    & > div {
      flex: 1;
    }
  }
  .user-c-r {
    text-align: right;
  }
  .user-img-tit {
    text-align: center;
    padding: 10px 0;
  }
  .user-c-img > img {
    width: 100%;
    height: 80px;
  }
  .user-img:not(:last-child) {
    padding-right: 10px;
  }
}

.user-item {
  width: 100%;
  display: flex;
  line-height: 50px;
  color: #141614;
}
.user-border {
  border-bottom: 1px solid #e8e8e8;
}
.uesr-tit {
  flex: 1;
  text-align: left;
}
.uesr-icon {
  flex: 1;
  text-align: right;
}
.user-footer {
  width: 100%;

  background: #f5f5f5;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.18);
  position: fixed;
  bottom: 0;
  left: 0;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
}
.require-txt {
  border-radius: 6px 6px 6px 0;
  color: #fff;
  background: #e8534c;
  font-size: 8px;
  padding: 2px 5px;
  vertical-align: bottom;
}
.icon-biji {
  font-size: 30px;
}
</style>